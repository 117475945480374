import React, { FC, useState } from 'react';
import { observer } from 'mobx-react';
import { Instance } from 'mobx-state-tree';

import { useStore } from '../../../contexts/storeContext';
import { City } from '../../../stores/cities';
import { withTranslation, WithTranslationType } from '../../../server/i18n';
import appConfig from '../../../helpers/config';

import ActiveCityTabContent from './HomeActiveCityTabContent';
import ActiveTabContent from '../../../components/home/tabs/ActiveTabContent';
import Tabs from '../../../components/home/tabs/Tabs';
import { TRACKER_CATEGORY_HOME_LANDING_PAGE } from '../../../helpers/trackerConstants';

import './home-explore-cities.scss';

const {
  publicRuntimeConfig: { DEFAULT_CITIES },
} = appConfig;

export const HomeExploreCities: FC<WithTranslationType> = observer(({ t }) => {
  const {
    config,
    i18nUtils: { umRegion },
    tracker,
  } = useStore();

  const citiesList = config.getValue('hero.portal.home.cities-list', umRegion);

  const defaultCity =
    citiesList &&
    citiesList.length &&
    citiesList.find((city: Instance<typeof City>) => city.id === DEFAULT_CITIES[umRegion]);

  const [activeCityTab, setActiveCityTab] = useState(defaultCity && defaultCity.id);

  const tabList = citiesList.map((city: Instance<typeof City>) => {
    return {
      id: city.id,
      name: city.name,
    };
  });

  const changeActiveTab = (id: string): void => {
    setActiveCityTab(id);

    tracker.track('Active city changed', {
      category: TRACKER_CATEGORY_HOME_LANDING_PAGE,
      city: id,
    });
  };

  return (
    <div className="home-explore-cities">
      <div className="columns home-explore-cities__container">
        <div className="column is-12">
          <h1 className="home-explore-cities__title">{t('home_explore-cities-title')}</h1>
          <Tabs activeTab={activeCityTab} changeActiveTab={changeActiveTab} tabList={tabList} />
          <ActiveTabContent>
            <ActiveCityTabContent city={activeCityTab} />
          </ActiveTabContent>
        </div>
      </div>
    </div>
  );
});

export default withTranslation('home')(HomeExploreCities);
