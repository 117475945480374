import React, { useState } from 'react';
import { scroller } from 'react-scroll';
import { useStore } from '../../contexts/storeContext';
import { withTranslation, WithTranslationType, Trans } from '../../server/i18n';

import Carousel from '../../components/home/Carousel';
import Link from '../../components/Link';
import ProgressiveImage from '../../components/ProgressiveImage';

import {
  LEARN_MORE_SECTION,
  SCROLL_DELAY,
  SCROLL_DURATION,
  SCROLL_OFFSET,
} from '../../helpers/constants';

import './home-how-urban-works.scss';

export const HomeHowUrbanWorks = ({ i18n, t }: WithTranslationType): JSX.Element => {
  const [activeSlide, setActiveSlide] = useState(0);
  const { cmsContent } = useStore();
  const stepsImages = cmsContent.getValue(
    i18n.language === 'fr-fr' ? 'frfr_how_urban_works' : 'engb_how_urban_works',
  );

  const scrollTo = (): void => {
    scroller.scrollTo(LEARN_MORE_SECTION, {
      duration: SCROLL_DURATION,
      delay: SCROLL_DELAY,
      offset: SCROLL_OFFSET + 150,
      smooth: true,
    });
  };

  const slides = [
    {
      subtitle: t('how-urban-works_step-one-subtitle'),
      text: (
        <Trans t={t} i18nKey="how-urban-works_step-one-text">
          Our simple onboarding process helps us get to know you, your skills and your
          preferences.&nbsp;
          <Link className="home-how-urban-works__link" route="/signup">
            Apply now
          </Link>
          &nbsp;or&nbsp;
          <button
            className="home-how-urban-works__link"
            onClick={(): void => scrollTo()}
            type="button"
          >
            Learn more
          </button>
        </Trans>
      ),
      imageUrl: stepsImages[0].step,
    },
    /* eslint-disable no-magic-numbers */
    {
      subtitle: t('how-urban-works_step-two-subtitle'),
      text: t('how-urban-works_step-two-text'),
      imageUrl: stepsImages[1].step,
    },
    {
      subtitle: t('how-urban-works_step-three-subtitle'),
      text: t('how-urban-works_step-three-text'),
      imageUrl: stepsImages[2].step,
    },
    {
      subtitle: t('how-urban-works_step-four-subtitle'),
      text: t('how-urban-works_step-four-text'),
      imageUrl: stepsImages[3].step,
    },
    {
      subtitle: t('how-urban-works_step-five-subtitle'),
      text: t('how-urban-works_step-five-text'),
      imageUrl: stepsImages[4].step,
    },
    {
      subtitle: t('how-urban-works_step-six-subtitle'),
      text: t('how-urban-works_step-six-text'),
      imageUrl: stepsImages[5].step,
    },
    {
      subtitle: t('how-urban-works_step-seven-subtitle'),
      text: t('how-urban-works_step-seven-text'),
      imageUrl: stepsImages[6].step,
    },
    {
      subtitle: t('how-urban-works_step-eight-subtitle'),
      text: t('how-urban-works_step-eight-text'),
      imageUrl: stepsImages[7].step,
    },
    {
      subtitle: t('how-urban-works_step-nine-subtitle'),
      text: t('how-urban-works_step-nine-text'),
      imageUrl: stepsImages[8].step,
    },
    /* eslint-enable no-magic-numbers */
  ];

  const { imageUrl } = slides[activeSlide];

  return (
    <div className="home-how-urban-works" id="js-how-urban-works">
      <div className="columns home-how-urban-works__container">
        <div className="column is-6 is-12-mobile home-how-urban-works__left-column">
          <section className="home-how-urban-works__section">
            <h1 className="home-how-urban-works__title" id="js-how-urban-works-step-title">
              {t('how-urban-works_title')}
            </h1>
            <h3
              className="home-how-urban-works__subtitle"
              id="js-how-urban-works-step-subtitle"
              data-qa="subtitle-how-urban-works"
            >
              {slides[activeSlide].subtitle}
            </h3>
            <p className="home-how-urban-works__text" id="js-how-urban-works-step-text">
              {slides[activeSlide].text}
            </p>
          </section>
        </div>
        <div className="column is-6 is-12-mobile home-how-urban-works__right-column">
          <Carousel
            length={slides.length}
            onActiveSlideChange={(active: number): void => setActiveSlide(active)}
          >
            <ProgressiveImage
              altText=""
              customClass="home-how-urban-works__carousel-item"
              disableWebp
              highResImg={imageUrl.replace('.png', '_3x.png')}
              highResImg2x={imageUrl.replace('.png', '_3x.png')}
              lowResImg={imageUrl.replace('/upload', '/upload/h_96,e_blur:2000,q_15')}
            />
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default withTranslation('how-urban-works')(HomeHowUrbanWorks);
