import React, { ReactNode } from 'react';
import { autorun, IReactionDisposer } from 'mobx';
import { inject, observer } from 'mobx-react';
import { NextRouter, withRouter } from 'next/router';
import { NextSeo } from 'next-seo';

import { withTranslation, WithTranslationType } from '../../server/i18n';

import HomeAreYouReady from './HomeAreYouReady';
import HomeExploreCities from './explore-cities/HomeExploreCities';

import HomeFaq from './HomeFaq';
import HomeHowUrbanWorks from './HomeHowUrbanWorks';
import HomeLearnMore from './HomeLearnMore';
import HomeTestimonials from './HomeTestimonials';
import HomeTopSection from './HomeTopSection';
import HomeWorkOnYourTerms from './HomeWorkOnYourTerms';
import LayoutWithHeaderAndFooter from '../../components/home/LayoutWithHeaderAndFooter';
import './index.scss';

interface IHomeProps extends WithTranslationType {
  router: NextRouter;
  session: {
    setReferrerCookie: (param: string) => void;
  };
}

@(withRouter as any) // eslint-disable-line @typescript-eslint/no-explicit-any
class Home extends React.Component<IHomeProps> {
  listeners: IReactionDisposer[];

  static async getInitialProps(_, { cmsContent }): Promise<{ namespacesRequired: string[] }> {
    cmsContent.loadContent();

    return {
      namespacesRequired: ['home', 'new-header', 'new-footer'],
    };
  }

  componentDidMount(): void {
    /* eslint-disable react/destructuring-assignment */
    this.listeners = [
      autorun(() => {
        const { router, session } = this.props;

        if (router.query && router.query.rf) {
          session.setReferrerCookie(router.query.rf as string);
        }
      }),
    ];
    /* eslint-enable react/destructuring-assignment */
  }

  componentWillUnmount(): void {
    while (this.listeners.length) {
      this.listeners.pop()();
    }
  }

  render(): ReactNode {
    const { t } = this.props;

    return (
      <LayoutWithHeaderAndFooter>
        <NextSeo
          title={t('home_meta-title')}
          description={t('home_meta-description')}
          openGraph={{
            type: 'website',
            site_name: 'Urban',
            url: t('home_og-url'),
            title: t('home_og-title'),
            description: t('home_og-description'),
            images: [
              {
                alt: t('home_og-alt-text'),
                url:
                  'https://res.cloudinary.com/huxr6hrje/image/upload/c_scale,w_192/v1517913336/windows-tile-310x310_tiifpp.png',
              },
            ],
          }}
        />
        <HomeTopSection />
        <HomeExploreCities />
        <HomeWorkOnYourTerms />
        <HomeHowUrbanWorks />
        <HomeTestimonials />
        <HomeLearnMore />
        <HomeFaq />
        <HomeAreYouReady />
      </LayoutWithHeaderAndFooter>
    );
  }
}

export default withTranslation('home')(
  inject(({ store: { cmsContent, session } }) => ({
    cmsContent,
    session,
  }))(observer(Home)),
);
