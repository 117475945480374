import React from 'react';
import { observer } from 'mobx-react';

import cn from 'classnames';
import { WithTranslationType } from '../../server/i18n';
import { browserOnly } from '../EnvSplit';
import { SUPPORTED_LANG } from '../../helpers/constants';

import './country-select.scss';

export const CountrySelect = observer((props: WithTranslationType): JSX.Element => {
  const { i18n, t } = props;

  const changeLanguage = (lang: string) => {
    // i18n.changeLanguage(lang);
    // setCurrentLang(lang);

    // do full page reload until we figure out all external scripts is still working without page reload
    window.location.assign(window.location.href.replace(i18n.language, lang));
  }

  return (
    <div className="country-select">
      <ul className="country-select__list">
        {SUPPORTED_LANG.map(lang => {
          return (
            <li className={cn('country-select__list-item', { 'country-select__list-item--active': i18n.language === lang })} key={lang}>
              <a
                data-qa={lang}
                data-testid={`js-${lang}`}
                id={`js-${lang}`}
                onClick={() => changeLanguage(lang)}
              >
                {t(`new-footer_country-${lang}`)}
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
});

export default browserOnly(CountrySelect);
