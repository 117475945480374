import React, { FC } from 'react';
import Link from '../Link';
import ContextualLoginButton from '../ContextualLoginButton';

import { withTranslation, WithTranslationType } from '../../server/i18n';

import './header.scss';

const Header: FC<WithTranslationType> = props => {
  const { t } = props;
  return (
    <div className="new-header__is-overlay columns is-centered new-container new-container--no-max">
      <div className="column">
        <div className="new-header columns is-vcentered is-mobile">
          <div className="new-header__logo-wrapper new-header__column is-vcentered column is-mobile is-one-fourth">
            <Link route="/" className="new-header__logo-link">
              <img
                className="new-header__logo"
                src="/static/images/urban-logo-hero-new.svg"
                alt={t('new-header_logo-alt')}
              />
            </Link>
          </div>
          <div data-qa="header-login-button" className="new-header__user-controls-wrapper column">
            <ContextualLoginButton
              className="new-button new-button--login-btn"
              placementKey="header"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation('new-header')(Header);
