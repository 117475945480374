import React from 'react';
import { useStore } from '../../contexts/storeContext';
import { withTranslation, WithTranslationType } from '../../server/i18n';

import Link from '../../components/Link';

import { TRACKER_CATEGORY_HOME_LANDING_PAGE } from '../../helpers/trackerConstants';

import './home-are-you-ready.scss';

export const AreYouReady = ({ t }: WithTranslationType): JSX.Element => {
  const { tracker } = useStore();

  const onClick = (): void => {
    tracker.track('Apply now in the are you ready section clicked', {
      category: TRACKER_CATEGORY_HOME_LANDING_PAGE,
    })
  }

  return (
    <div className="home_are-you-ready">
      <div className="new-container columns">
        <div className="column home_are-you-ready__container">
          <h1 className="home_are-you-ready__title text-center">
            {t('home_are-you-ready-title')}
          </h1>
          <p className="home_are-you-ready__paragraph text-center">
            {t('home_are-you-ready-paragraph')}
          </p>
          <div data-qa="are-you-ready" className="home_are-you-ready__apply-now-button" onClick={onClick}>
            <Link
              className="new-button new-button--apply-btn"
              id="js-areYouReadyApplyNowButton"
              route="/signup"
              target="_blank"

            >
              {t('home_are-you-ready-apply-button')}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation('home')(AreYouReady);
