import { useReducer, useEffect } from 'react';

const TRANSLATE_VALUE = 5;

function previous(length: number, current: number): number {
  return (current - 1 + length) % length;
}

function next(length: number, current: number): number {
  return (current + 1) % length;
}

const transitionTime = 200;
const smooth = `transform ${transitionTime}ms ease`;

interface CarouselState {
  active: number;
  desired: number;
}

const initialCarouselState: CarouselState = {
  active: 0,
  desired: 0,
};

interface CarouselNextAction {
  type: 'next';
  length: number;
}

interface CarouselPrevAction {
  type: 'prev';
  length: number;
}

interface CarouselJumpAction {
  type: 'jump';
  desired: number;
}

interface CarouselDoneAction {
  type: 'done';
}

type CarouselAction =
  | CarouselJumpAction
  | CarouselNextAction
  | CarouselPrevAction
  | CarouselDoneAction;

function carouselReducer(state: CarouselState, action: CarouselAction): CarouselState {
  switch (action.type) {
    case 'jump':
      return {
        ...state,
        desired: action.desired,
      };
    case 'next':
      return {
        ...state,
        desired: next(action.length, state.active),
      };
    case 'prev':
      return {
        ...state,
        desired: previous(action.length, state.active),
      };
    case 'done':
      return {
        ...state,
        active: state.desired,
      };
    default:
      return state;
  }
}

export function useCarousel(): [number, (n: number) => void, React.CSSProperties] {
  const [state, dispatch] = useReducer(carouselReducer, initialCarouselState);

  const style: React.CSSProperties = {
    transition: 'opacity 1',
    transform: 'translateX(0)',
  };

  useEffect(() => {
    const id = setTimeout(() => dispatch({ type: 'done' }), transitionTime);
    return (): void => clearTimeout(id);
  }, [state.desired]);

  if (state.desired !== state.active) {
    style.transition = smooth;
    style.transform = `translateX(${TRANSLATE_VALUE}%)`;
  }

  return [state.active, (num): void => dispatch({ type: 'jump', desired: num }), style];
}
