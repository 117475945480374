import React from 'react';

import cn from 'classnames';

import Tab, { TabType } from './Tab';

import './tabs.scss';

interface TabsProps {
  activeTab: string;
  changeActiveTab?: Function;
  className?: string;
  tabList: Array<TabType>;
  wrapperClassName?: string;
}

export const Tabs = (props: TabsProps): JSX.Element => {
  return (
    <div className={cn('tabs', props.wrapperClassName)}>
      <ul className={cn(props.className)}>
        {props.tabList && props.tabList.map((tab: TabType) => (
          <Tab
            activeTab={props.activeTab}
            changeActiveTab={props.changeActiveTab}
            key={tab.id}
            tab={tab}
          />
        ))}
      </ul>
    </div>
  );
};

export default Tabs;
