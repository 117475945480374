import React, { useState } from 'react';
import cn from 'classnames';
import { useStore } from '../../contexts/storeContext';
import { withTranslation, WithTranslationType } from '../../server/i18n';
import { useScrollPosition } from '../../hooks/useScrollPosition';

import Link from '../../components/Link';
import { TRACKER_CATEGORY_HOME_LANDING_PAGE } from '../../helpers/trackerConstants';

import './home-top-section.scss';

const SHOW_STICKY_BUTTON_AFTER = 580;

export const HomeTopSection = ({ t }: WithTranslationType): JSX.Element => {
  const [showOnScroll, setHideOnScroll] = useState(false);
  const { tracker } = useStore();

  useScrollPosition(
    ({ currPos }) => {
      const isShow = currPos && currPos.y > SHOW_STICKY_BUTTON_AFTER;
      if (isShow !== showOnScroll) setHideOnScroll(isShow);
    },
    [showOnScroll],
  );

  const onClick = (): void => {
    tracker.track('Apply now clicked', {
      category: TRACKER_CATEGORY_HOME_LANDING_PAGE,
    });
  };

  return (
    <div className="columns home-top-section new-container new-container--no-max new-container">
      <div className="column is-6 is-12-mobile home-top-section__column">
        <section className="home-top-section__left-column">
          <h1 className="home-top-section__title">{t('home_top-section-title')}</h1>
          <p>{t('home_top-section-subtitle')}</p>
          <div
            data-qa="apply-now-button"
            className="home-top-section__button-container"
            onClick={onClick}
          >
            <Link
              className={cn('new-button new-button--apply-btn', {
                'new-button--sticky-apply-btn': showOnScroll,
              })}
              id="js-applyNowButton"
              route="/signup"
            >
              {t('home_top-section-apply-button')}
            </Link>
          </div>
        </section>
      </div>
      <div className="column is-6 is-12-mobile">
        <div className="home-top-section__right-column" />
      </div>
    </div>
  );
};

export default withTranslation('home')(HomeTopSection);
