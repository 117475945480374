import React from 'react';
import { observer } from 'mobx-react';
import { Trans, withTranslation, WithTranslationType } from '../../server/i18n';
import { useStore } from '../../contexts/storeContext';

import config from '../../helpers/config';
import CountrySelect from './CountrySelect';
import ProgressiveImage from '../ProgressiveImage';

import './footer.scss';

import fbIcon from '../../../public/static/images/icons/logo-fb-simple.svg';
import twitterIcon from '../../../public/static/images/icons/logo-twitter.svg';
import instagramIcon from '../../../public/static/images/icons/logo-instagram.svg';
import linkedinIcon from '../../../public/static/images/icons/logo-linkedin.svg';

const { publicRuntimeConfig } = config;
const { B2C_URL } = publicRuntimeConfig;

export const Footer = observer((props: WithTranslationType) => {
  const store = useStore();
  const {
    config,
    i18nUtils: { umRegion },
  } = store;
  const { i18n, t } = props;

  const facebookUrl = config.getValue('locale.social.facebook-url', umRegion);
  const instagramUrl = config.getValue('locale.social.instagram-url', umRegion);
  const linkedinUrl = config.getValue('locale.social.linkedin-url', umRegion);
  const twitterUrl = config.getValue('locale.social.twitter-url', umRegion);

  return (
    <div className="new-footer columns is-centered">
      <div className="column new-footer__container" id="js-footer">
        <div className="is-vcentered is-mobile">
          <CountrySelect i18n={i18n} t={t} />
          <div className="columns is-vcentered">
            <div className="column is-6">
              <p className="new-footer__text">
                <Trans t={t} i18nKey="new-footer_b2c-link-note">
                  Looking for a practitioner? Please visit&nbsp;
                  <a
                    className="new-footer__b2c-link"
                    href={`${B2C_URL}/${i18n.language}/`}
                    id="js-footerB2CLink"
                  >
                    urban.co
                  </a>
                </Trans>
              </p>
            </div>
            <div className="column new-footer__social-links is-6">
              <a
                className="new-footer__social-icon-link"
                href={facebookUrl}
                id="js-footerFacebookLink"
                rel="noopener noreferrer"
                target="_blank"
                data-qa="footer-facebook"
              >
                <ProgressiveImage
                  altText={t('new-footer_facebook-logo-alt')}
                  className="new-footer__social-icon"
                  disableWebp
                  highResImg={fbIcon}
                  highResImg2x={fbIcon}
                  lowResImg={fbIcon}
                />
              </a>
              <a
                className="new-footer__social-icon-link"
                href={twitterUrl}
                id="js-footerTwitterLink"
                rel="noopener noreferrer"
                target="_blank"
                data-qa="footer-twitter"
              >
                <ProgressiveImage
                  altText={t('new-footer_twitter-logo-alt')}
                  className="new-footer__social-icon"
                  disableWebp
                  highResImg={twitterIcon}
                  highResImg2x={twitterIcon}
                  lowResImg={twitterIcon}
                />
              </a>
              <a
                className="new-footer__social-icon-link"
                href={instagramUrl}
                id="js-footerInstagramLink"
                rel="noopener noreferrer"
                target="_blank"
                data-qa="footer-instagram"
              >
                <ProgressiveImage
                  altText={t('new-footer_instagram-logo-alt')}
                  className="new-footer__social-icon"
                  disableWebp
                  highResImg={instagramIcon}
                  highResImg2x={instagramIcon}
                  lowResImg={instagramIcon}
                />
              </a>
              <a
                className="new-footer__social-icon-link"
                href={linkedinUrl}
                id="js-footerLinkedinLink"
                rel="noopener noreferrer"
                target="_blank"
                data-qa="footer-linkedin"
              >
                <ProgressiveImage
                  altText={t('new-footer_linkedin-logo-alt')}
                  className="new-footer__social-icon"
                  disableWebp
                  highResImg={linkedinIcon}
                  highResImg2x={linkedinIcon}
                  lowResImg={linkedinIcon}
                />
              </a>
            </div>
          </div>
          <p className="new-footer__text new-footer__copyright">{t('new-footer_copyright-note')}</p>
        </div>
      </div>
    </div>
  );
});

export default withTranslation('new-footer')(Footer);
