import React, { FC, useState, useRef } from 'react';

import cn from 'classnames';

import './accordion.scss';

import minusIcon from '../../../public/static/images/icons/minus-solid.svg';
import plusIcon from '../../../public/static/images/icons/plus-18.svg';

interface Props {
  content: string;
  title: string;
}

export const Accordion: FC<Props> = (props: Props) => {
  const [active, setActive] = useState(false);
  const [height, setHeight] = useState('0px');

  const content = useRef(null);

  const toggle = () => {
    setActive(!active);
    setHeight(active ? '0px' : `${content.current.scrollHeight}px`);
  };

  return (
    <div className="accordion__section">
      <button
        className={cn('accordion', { 'accordion--active': active })}
        onClick={toggle}
        type="button"
      >
        <p className="accordion__title" data-qa="accordion-title">
          {props.title}
        </p>
        <img alt="" className="accordion__icon" src={active ? minusIcon : plusIcon} />
      </button>
      <div className="accordion__content" ref={content} style={{ maxHeight: `${height}` }}>
        <div
          className="accordion__text"
          data-qa="accordion-text"
          dangerouslySetInnerHTML={{
            __html: props.content,
          }}
        />
      </div>
    </div>
  );
};

export default Accordion;
