import React from 'react';

import cn from 'classnames';
import { useStore } from '../../../contexts/storeContext';

import ProgressiveImage from '../../../components/ProgressiveImage';
import { TRACKER_CATEGORY_HOME_LANDING_PAGE } from '../../../helpers/trackerConstants';

interface Props {
  id: string;
  imageUrl: string;
  isActive: boolean;
  setActiveVertical: Function;
  title: string;
}

export const VerticalThumbnail = ({
  id,
  imageUrl,
  isActive,
  setActiveVertical,
  title,
}: Props): JSX.Element => {
  const { tracker } = useStore();

  const changeVertical = (id: string) => {
    setActiveVertical(id);

    tracker.track('Vertical changed', {
      category: TRACKER_CATEGORY_HOME_LANDING_PAGE,
      vertical: id,
    });
  };

  return (
    <div
      className={cn('columns', 'home-explore-cities__vertical', {
        'home-explore-cities__vertical--active': isActive,
      })}
      id={`js-vertical-${id}`}
      onClick={(): void => changeVertical(id)}
      data-qa={`js-vertical-${id}`}
    >
      <div className="column is-4 home-explore-cities__vertical-image">
        <ProgressiveImage
          altText=""
          highResImg={imageUrl.replace('.jpg', '_2x.jpg')}
          highResImg2x={imageUrl.replace('.jpg', '_3x.jpg')}
          lowResImg={imageUrl.replace('/upload', '/upload/c_scale,h_124,e_blur:2000,q_15')}
        />
      </div>
      <div className="column is-8 home-explore-cities__vertical-title">{title}</div>
    </div>
  );
};

export default VerticalThumbnail;
