import React from 'react';

import cn from 'classnames';

export interface TabType {
  className?: string;
  id: string;
  name: string;
  titleContent?: JSX.Element;
}

export interface TabProps {
  activeTab: string;
  changeActiveTab?: Function;
  tab: TabType;
}

export const Tab = (props: TabProps): JSX.Element => {
  const { className, id, name, titleContent } = props.tab;
  const { activeTab, changeActiveTab } = props;

  return (
    <li className={cn('tab', { 'tab--is-active': id === activeTab }, className)}>
      {titleContent || (
        <button
          className="tab__button"
          data-qa={`tab-${id}`}
          data-testid={`tab-${id}`}
          id={`js-tab-${id}`}
          onClick={() => changeActiveTab(id)}
          type="button"
        >
          <span>{name}</span>
        </button>
      )}
    </li>
  );
};

export default Tab;
