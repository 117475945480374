import React, { FC, ReactElement, useEffect, useState } from 'react';
import { Instance } from 'mobx-state-tree';
import appConfig from '../../helpers/config';
import { i18n, withTranslation, WithTranslationType } from '../../server/i18n';
import { useStore } from '../../contexts/storeContext';
import { Article } from '../../stores/articles';

import Accordion from '../../components/home/Accordion';

import './home-faq.scss';

const {
  publicRuntimeConfig: { SALESFORCE_FAQ_BASE_URL },
} = appConfig;

const FAQ_SLUGS_EN = 'engb_faq_slugs';
const FAQ_SLUGS_FR = 'frfr_faq_slugs';

interface HomeFaqProps extends WithTranslationType {}

const HomeFaq: FC<HomeFaqProps> = ({ i18n, t }: HomeFaqProps) => {
  const { i18nUtils } = useStore();

  return (
    <div className="new-container home-faq columns">
      <div className="column">
        <h1 className="home-faq__title" data-qa="faq-title">{t('home_faq-title')}</h1>
        <Faqs />
        <p className="home-faq__show-all-link">
          <a
            className="anchor home-faq__link"
            id="js-faqsLink"
            data-qa="faq-link"
            href={
              i18nUtils.umRegion === 'UK' ?
              SALESFORCE_FAQ_BASE_URL :
              `/${i18n.language}/faq`
            }
            rel="noopener noreferrer"
            target="_blank"
          >
            {`${t('home_faq-link')} `}
            <span className="arrow-right" />
          </a>
        </p>
      </div>
    </div>
  );
};

const Faqs = (): ReactElement => {
  const { articles, cmsContent } = useStore();
  const [ faqArticles, setFaqArticles ] = useState<Instance<typeof Article>[]>([]);
  const currentLanguage = i18n.language;
  const faqSlugsKey = currentLanguage === 'fr-fr' ? FAQ_SLUGS_FR : FAQ_SLUGS_EN;
  //TODO instead of slugs store articles in cms
  const faqSlugs = cmsContent.getValue(faqSlugsKey);

  useEffect(() => {
    if (faqSlugs) {
      articles
        .getArticles(faqSlugs.map(i => i.faq_slug), currentLanguage)
        .then(articlesResult => {
          setFaqArticles(articlesResult);
        })
    }
  }, [])

  return (
    <>
      {faqArticles && faqArticles.map(article => {
        return <Accordion content={article.content} key={article.id} title={article.title} />;
      })}
    </>
  );
};

export default withTranslation('home')(HomeFaq);

