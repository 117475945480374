import React from 'react';
import { Trans, withTranslation, WithTranslationType } from '../../server/i18n';

import ProgressiveImage from '../../components/ProgressiveImage';

import './home-work-on-your-terms.scss';

export const HomeWorkOnYourTerms = ({ t }: WithTranslationType): JSX.Element => (
  <div className="columns new-container home-work-on-your-terms">
    <div className="column is-6 is-12-mobile">
      <h1 className="home-work-on-your-terms__title">
        {t('home_work-on-your-terms-title')}
      </h1>
      <p className="home-work-on-your-terms__text">
        <Trans t={t} i18nKey="home_work-on-your-terms-paragraph">
          You choose <i>when</i> and <i>where</i> you want to work and demant data will be&nbsp;
          there to help you out to decide.
        </Trans>
      </p>
    </div>
    <div className="column is-6 is-12-mobile home-work-on-your-terms__right-section">
      <p className="text-center home-work-on-your-terms__demand-title">{t('home_work-on-your-terms-demand-title')}</p>
      <ProgressiveImage
        altText=""
        customClass="home-work-on-your-terms__demand-image"
        disableWebp
        highResImg="https://res.cloudinary.com/huxr6hrje/image/upload/v1576838390/hero-web/home-images/demand.svg"
        highResImg2x="https://res.cloudinary.com/huxr6hrje/image/upload/v1576838390/hero-web/home-images/demand.svg"
        lowResImg="https://res.cloudinary.com/huxr6hrje/image/upload/e_blur:2000,q_15/v1576838390/hero-web/home-images/demand.png"
      />
      <div className="columns text-center is-mobile home-work-on-your-terms__demand-text">
        <div className="column is-4">{t('home_work-on-your-terms-low-demand')}</div>
        <div className="column is-4">{t('home_work-on-your-terms-usual-demand')}</div>
        <div className="column is-4">{t('home_work-on-your-terms-high-demand')}</div>
      </div>
    </div>
  </div>
);

export default withTranslation('home')(HomeWorkOnYourTerms);
