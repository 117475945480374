/* eslint-disable no-underscore-dangle */
import React from 'react';
import { action, IReactionDisposer, observable, reaction } from 'mobx';
import { observer, inject } from 'mobx-react';
import autobind from 'autobind-decorator';
import cn from 'classnames';
import appConfig from '../../helpers/config';

import { browserOnly } from '../EnvSplit';
import { Trans, withTranslation, WithTranslationType } from '../../server/i18n';

import Popup from '../Popup';

import closeIcon from '../../../public/static/images/icons/cancel-medium-light.svg';
import alertIcon from '../../../public/static/images/icons/alert-big.svg';

import './salesforce-chat-button.scss';

const {
  publicRuntimeConfig: { SALESFORCE_SCRIPT_URL },
} = appConfig;

const LIVEAGENT_INIT_ID_1 = '5720N0000008aBN';
const LIVEAGENT_INIT_ID_2 = '00D0N000000gJ18';

interface ChatButtonProps extends WithTranslationType {
  config?: {
    getValue: Function;
  };
  i18nUtils?: {
    umRegion: string;
  };
  initialLoadComplete?: boolean;
  screen?: {
    isDesktop: boolean;
  };
  session?: {
    user: {
      id: number;
      name: string;
    };
  };
  tracker?: {
    track: Function;
  };
}

const TIMEOUT_DELAY = 2000;

@browserOnly
@inject(({ store: { config, i18nUtils, initialLoadComplete, screen, session, tracker } }) => ({
  config,
  i18nUtils,
  initialLoadComplete,
  screen,
  session,
  tracker,
}))
@observer
class ChatButton extends React.Component<ChatButtonProps, null> {
  clickedUpgrade: any;
  csWorkingHours: any;
  faqUrl: string;
  listeners: IReactionDisposer[];
  salesforceButtonId: string;
  timeout: NodeJS.Timeout;
  zendeskLocale: any;

  @observable hasStartedChat = false;
  @observable isChatOpen = false;
  @observable isUserChat = false;
  @observable showChatOfflinePopup = false;

  constructor(props: ChatButtonProps) {
    super(props);
    const { config, i18nUtils } = this.props;

    this.zendeskLocale = config.getValue('locale.zendesk-locale-id', i18nUtils.umRegion);

    this.csWorkingHours = config.getValue('locale.cs-online-working-hours', i18nUtils.umRegion);

    this.salesforceButtonId = '5730N0000008aRn';

    this.faqUrl = config.getValue('b2c.faq-url', i18nUtils.umRegion);
  }

  componentDidMount(): void {
    if (!window._laq) {
      window._laq = [];
    }

    const { session } = this.props;
    const { user } = session;

    /* eslint-disable react/destructuring-assignment */
    this.listeners = [
      reaction(
        () => session && session.user && session.user.id,
        id => {
          this.loadChat(id);
        },
        {
          name: 'Create chat session when user signs in',
        },
      ),
      reaction(
        () => this.props.initialLoadComplete,
        initialLoadComplete => {
          if (initialLoadComplete) {
            const userId = (user && user.id) || undefined;

            this.timeout = setTimeout(() => {
              this.loadChat(userId);
            }, TIMEOUT_DELAY);
          }
        },
        {
          fireImmediately: true,
          name: 'Check if content has finished loading',
        },
      ),
    ];
    /* eslint-enable react/destructuring-assignment */
  }

  componentWillUnmount(): void {
    delete window.liveagent;
    delete window.liveAgentDeployment;

    while (this.listeners.length) {
      this.listeners.pop()();
    }

    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = undefined;
    }

    // This mock-agent suppresses errors caused by pending avail-checks
    window.liveagent = { _: { handlePing: (): void => {} } }; // eslint-disable-line
  }

  @action('onCloseTooltip - SalesforceChatButton')
  onCloseToolTip(): void {
    this.showChatOfflinePopup = false;
  }

  /*
   *  This function adds a new script from url parameter to the document head and allows a callback
   *  to be called once the script loads
   */
  loadScript(url: string, callback: () => void): void {
    const script: any = document.createElement('script');
    script.type = 'text/javascript';

    if (script.readyState) {
      //IE
      script.onreadystatechange = (): void => {
        if (script.readyState === 'loaded' || script.readyState === 'complete') {
          script.onreadystatechange = null;
          callback();
        }
      };
    } else {
      //Others
      script.onload = (): void => {
        callback();
      };
    }

    script.src = url;
    document.getElementsByTagName('head')[0].appendChild(script);
  }

  /*
   *  Reload the Salesforce Live Agent
   */
  @autobind
  loadChat(userId): void {
    // We need to delete all the liveagent objects
    delete window.liveagent;
    delete window.liveAgentDeployment;

    // This mock-agent suppresses errors caused by pending avail-checks
    window.liveagent = { _: { handlePing: (): void => {} } }; // eslint-disable-line

    this.loadScript(
      `https://c.${SALESFORCE_SCRIPT_URL}.salesforceliveagent.com/content/g/js/42.0/deployment.js`,
      (): void => {
        if (
          window.liveagent &&
          typeof window.liveagent.showWhenOnline === 'function' &&
          typeof window.liveagent.showWhenOffline === 'function'
        ) {
          window._laq.push(() => {
            if (
              window &&
              window.liveagent &&
              typeof window.liveagent.showWhenOnline === 'function' &&
              typeof window.liveagent.showWhenOffline === 'function'
            ) {
              window.liveagent.showWhenOnline(
                this.salesforceButtonId,
                document.getElementById(`liveagent_button_online_${this.salesforceButtonId}`),
              );

              window.liveagent.showWhenOffline(
                this.salesforceButtonId,
                document.getElementById(`liveagent_button_offline_${this.salesforceButtonId}`),
              );
            }
          });
        }

        if (userId && !this.isUserChat) {
          this.isUserChat = true;

          const {
            session: {
              user: { name },
            },
          } = this.props;

          window.liveagent.setName(name);

          // Set the UM_User_ID__c variable with the user id, in order to be sent to Salesforce chat
          // so that in CS they can know the details of the UM user in the chat
          window.liveagent
            .addCustomDetail('UM_User_ID__c', `user-${userId}`)
            .saveToTranscript('UM_User_ID__c');

          // Add UM_User_ID__c variable to the Contact info on the current chat session and then link
          // it to the Salesforce case
          window.liveagent
            .findOrCreate('Contact')
            .map('UM_User_ID__c', 'UM_User_ID__c', true, true, true);
          window.liveagent
            .findOrCreate('Contact')
            .saveToTranscript('ContactId')
            .showOnCreate()
            .linkToEntity('Case', 'ContactId');
        }

        if (!userId && this.isUserChat) {
          this.isUserChat = false;
        }

        // Set the Subject to the current chat session
        window.liveagent.addCustomDetail('Subject', 'Chat Session (WEB-B2C)');

        // Set the Case status of the current chat session
        window.liveagent.addCustomDetail('Case Status', 'New');

        // Link the Subject and Case Status to the current case
        window.liveagent
          .findOrCreate('Case')
          .map('Subject', 'Subject', true, false, true)
          .map('Status', 'Case Status', false, false, true)
          .saveToTranscript('CaseId')
          .showOnCreate();

        // Initialise the Salesforce liveagent
        window.liveagent.init(
          `https://d.${SALESFORCE_SCRIPT_URL}.salesforceliveagent.com/chat`,
          LIVEAGENT_INIT_ID_1,
          LIVEAGENT_INIT_ID_2,
        );
      },
    );
  }

  /*
   *  Open the chat window when the user clicks the chat button
   */
  @autobind
  @action('openChat - SalesforceChatButton')
  openChat(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void {
    event.preventDefault();
    const {
      session: { user },
      tracker,
    } = this.props;

    tracker.track('Clicked to open chat - Online', {
      category: 'Chat',
      label: (user && user.id) || undefined,
    });

    this.isChatOpen = true;

    if (!this.hasStartedChat) {
      this.hasStartedChat = true;

      window.liveagent.startChatWithWindow(this.salesforceButtonId, 'chat-frame');
    }
  }

  /*
   *  Open the information popup if the user clicks the chat button and CS is offline
   */
  @autobind
  @action('openChatOffline - SalesforceChatButton')
  openChatOffline(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
    event.preventDefault();

    const {
      session: { user },
      tracker,
    } = this.props;

    tracker.track('Clicked to open chat - Offline', {
      category: 'Chat',
      label: (user && user.id) || undefined,
    });

    this.showChatOfflinePopup = true;
  }

  @autobind
  @action('dismissPopup - SalesforceChatButton')
  dismissPopup(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void {
    event.preventDefault();

    this.showChatOfflinePopup = false;
  }

  @autobind
  @action('endChat - SalesforceChatButton')
  endChat(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
    event.preventDefault();

    this.isChatOpen = false;

    document.getElementById('chat-frame').setAttribute('src', '');
    this.hasStartedChat = false;
  }

  @autobind
  @action('minimiseChat - SalesforceChatButton')
  minimiseChat(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
    event.preventDefault();

    this.isChatOpen = false;
  }

  render(): JSX.Element {
    const {
      csWorkingHours,
      isChatOpen,
      openChat,
      openChatOffline,
      props,
      showChatOfflinePopup,
      zendeskLocale,
    } = this;
    const { screen, t } = props;

    return (
      <div>
        <a
          className="new-chat-button"
          href="#"
          id={`liveagent_button_online_${this.salesforceButtonId}`}
          onClick={openChat}
          style={{
            display: 'none',
          }}
        >
          {/* <span className="chat-button__text">{t('chat_button-text')}</span> */}
        </a>
        <button
          className="new-chat-button"
          id={`liveagent_button_offline_${this.salesforceButtonId}`}
          onClick={openChatOffline}
          style={{
            display: 'none',
          }}
          type="button"
        >
          <span className="new-chat-button__text">{t('chat_button-text')}</span>
        </button>
        <div
          className={cn(
            'new-chat-button__iframe-container',
            isChatOpen && 'new-chat-button__iframe-container--active',
          )}
        >
          <div className="new-chat-button__popup-topbar">
            <button
              className="new-chat-button__minimise-button"
              onClick={this.minimiseChat}
              type="button"
            >
              <span className="new-chat-button__minimise-content" />
            </button>
            <button
              className="new-chat-button__end-chat-button"
              onClick={this.endChat}
              type="button"
            >
              {t('chat_close-chat-text')}
            </button>
          </div>

          <iframe
            className="new-chat-button__chat-container"
            id="chat-frame"
            name="chat-frame"
            title="chat-frame"
          />
        </div>
        {showChatOfflinePopup && !screen.isDesktop && (
          <Popup>
            <div className="new-salesforce-chat-button__offline-popup">
              <img
                alt=""
                className="new-salesforce-chat-button__offline-popup-icon"
                src={alertIcon}
              />
              <h1 className="new-salesforce-chat-button__offline-popup-title">
                {t('chat_offline-title')}
              </h1>
              <div
                className="new-salesforce-chat-button__offline-popup-close-button"
                onClick={this.dismissPopup}
              >
                <img alt="" height="20" src={closeIcon} width="20" />
              </div>
              <div className="new-salesforce-chat-button__offline-popup-body">
                <p className="new-salesforce-chat-button__offline-popup-body-content">
                  <Trans i18nKey="chat_offline-working-hours">
                    Our support team is online from
                    {csWorkingHours}.
                  </Trans>
                </p>
                <p className="new-salesforce-chat-button__offline-popup-body-content">
                  {t('chat_offline-check-faq')}
                </p>
                <div className="new-salesforce-chat-button__offline-popup-buttons-container row">
                  <div className="col-md-6 new-salesforce-chat-button__offline-popup-buttons-container--left">
                    <a
                      className="
                        button
                        button--secondary
                        button--caps
                        button--normal
                        button--block
                        new-salesforce-chat-button__offline-popup-button
                        new-salesforce-chat-button__offline-popup-button-mail
                      "
                      href="mailto:info@urbanmassage.com"
                    >
                      <span>{t('chat_offline-email-button')}</span>
                    </a>
                  </div>
                  <div className="col-md-6 new-salesforce-chat-button__offline-popup-buttons-container--right">
                    <a
                      className="
                        button
                        button--primary
                        button--caps
                        button--normal
                        button--block
                        new-salesforce-chat-button__offline-popup-button
                      "
                      href={`${this.faqUrl}${zendeskLocale}/`}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <span>{t('chat_offline-faq-button')}</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </Popup>
        )}
        {showChatOfflinePopup && screen.isDesktop && (
          <div className="new-salesforce-chat-button__offline-desktop-popup-container">
            <div className="new-salesforce-chat-button__offline-popup">
              <img
                alt=""
                className="new-salesforce-chat-button__offline-popup-icon"
                src={alertIcon}
              />
              <h1 className="new-salesforce-chat-button__offline-popup-title">
                {t('chat_offline-title')}
              </h1>
              <div
                className="new-salesforce-chat-button__offline-popup-close-button"
                onClick={this.dismissPopup}
              >
                <img alt="" height="20" src={closeIcon} width="20" />
              </div>
              <div className="new-salesforce-chat-button__offline-popup-body">
                <p className="new-salesforce-chat-button__offline-popup-body-content">
                  <Trans i18nKey="chat_offline-working-hours">
                    Our support team is online from
                    {csWorkingHours}.
                  </Trans>
                </p>
                <p className="new-salesforce-chat-button__offline-popup-body-content">
                  {t('chat_offline-check-faq')}
                </p>
                <div className="new-salesforce-chat-button__offline-popup-buttons-container row">
                  <div className="col-sm-6 new-salesforce-chat-button__offline-popup-buttons-container--left">
                    <a
                      className="
                        button
                        button--secondary
                        button--caps
                        button--normal
                        button--block
                        new-salesforce-chat-button__offline-popup-button
                        new-salesforce-chat-button__offline-popup-button-mail
                      "
                      href="mailto:info@urban.com"
                      onClick={this.onCloseToolTip}
                    >
                      <span>{t('chat_offline-email-button')}</span>
                    </a>
                  </div>
                  <div className="col-sm-6 new-salesforce-chat-button__offline-popup-buttons-container--right">
                    <a
                      className="
                        button
                        button--primary
                        button--caps
                        button--normal
                        button--block
                        new-salesforce-chat-button__offline-popup-button
                      "
                      href={`${this.faqUrl}${zendeskLocale}/`}
                      onClick={this.clickedUpgrade}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <span>{t('chat_offline-faq-button')}</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation('chat')(ChatButton);
