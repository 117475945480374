import React from 'react';

interface ActiveTabContentProps {
  children: JSX.Element;
}

export const ActiveTabContent = (props: ActiveTabContentProps): JSX.Element => (
  <div>{props.children}</div>
);

export default ActiveTabContent;
