import { useLayoutEffect, useEffect } from 'react';

const isBrowser: boolean = typeof window !== 'undefined';

const useIsomorphicLayoutEffect = isBrowser ? useLayoutEffect : useEffect;

interface ScrollPosition {
  x: number;
  y: number;
}

function getScrollPosition({ element, useWindow }): ScrollPosition {
  if (!isBrowser) return { x: 0, y: 0 };

  const target: HTMLElement = element ? element.current : document.body;
  const position = target.getBoundingClientRect();

  return useWindow
    ? {  x: window.pageXOffset, y: window.pageYOffset }
    : { x: position.left, y: position.top };
}

export function useScrollPosition(
  effect: Function,
  deps: Array<any>,
  element: HTMLElement = null,
  useWindow = true,
  wait = 50,
) {

  if (!isBrowser) return;

  let isScrolling: NodeJS.Timeout;
  const callback = () => {
    const currPos = getScrollPosition({ element, useWindow });
    effect({ currPos });
    if (isBrowser) {
      window.clearTimeout(isScrolling);
    }
    isScrolling = null;
  };

  useIsomorphicLayoutEffect(() => {
    const handleScroll = (): void => {
      if (wait) {
        if (isScrolling === null) {
          isScrolling = setTimeout(function() {
            callback();
          }, wait);
        } else {
          callback();
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, deps);
}
