import React from 'react';
import { Element } from 'react-scroll';
import { useStore } from '../../contexts/storeContext';
import { withTranslation, WithTranslationType } from '../../server/i18n';

import ProgressiveImage from '../../components/ProgressiveImage';

import { TRACKER_CATEGORY_HOME_LANDING_PAGE } from '../../helpers/trackerConstants';
import { LEARN_MORE_SECTION } from '../../helpers/constants';

import './home-learn-more.scss';

export const HomeLearnMore = ({ i18n, t }: WithTranslationType): JSX.Element => {
  let onboardingLink: string;
  let safetyFeaturesLink: string;
  let supportLink: string;

  const { cmsContent } = useStore();

  if (i18n.language === 'en-gb') {
    const links = cmsContent.getValue('engb_learn_more');
    onboardingLink = links && links.onboarding_link;
    safetyFeaturesLink = links && links.safety_features_link;
    supportLink = links && links.support_link;
  } else if (i18n.language === 'fr-fr') {
    const links = cmsContent.getValue('frfr_learn_more');
    onboardingLink = links && links.onboarding_link;
    safetyFeaturesLink = links && links.safety_features_link;
    supportLink = links && links.support_link;
  }

  return (
    <Element name={LEARN_MORE_SECTION}>
      <div className="home-learn-more new-container">
        <h1 className="home-learn-more__title" data-qa="learnmore-title">{t('home_learn-more-title')}</h1>
      </div>
      <div className="home-learn-more new-container">
        <div className="columns is-mobile home-learn-more__scrollable-content">
          <div className="column is-4 home-learn-more__card">
            <Card
              imageUrl="https://res.cloudinary.com/huxr6hrje/image/upload/v1577105132/hero-web/home-images/group-14.jpg"
              linkUrl={supportLink}
              subtitle={t('home_learn-more-support-title')}
              t={t}
              text={t('home_learn-more-support-text')}
            />
          </div>
          <div className="column is-4 home-learn-more__card">
            <Card
              imageUrl="https://res.cloudinary.com/huxr6hrje/image/upload/v1577105037/hero-web/home-images/group-11.jpg"
              linkUrl={safetyFeaturesLink}
              subtitle={t('home_learn-more-safety-features-title')}
              t={t}
              text={t('home_learn-more-safety-features-text')}
            />
          </div>
          <div className="column is-4 home-learn-more__card">
            <Card
              imageUrl="https://res.cloudinary.com/huxr6hrje/image/upload/v1577105214/hero-web/home-images/rectangle-3.jpg"
              linkUrl={onboardingLink}
              subtitle={t('home_learn-more-onboarding-features-title')}
              t={t}
              text={t('home_learn-more-onboarding-features-text')}
            />
          </div>
        </div>
      </div>
    </Element>
  );
};

interface CardProps {
  imageUrl: string;
  linkUrl: string;
  subtitle: string;
  t: (param: string) => string;
  text: string;
}

const Card = ({ imageUrl, linkUrl, subtitle, t, text }: CardProps) => {
  const { tracker } = useStore();
  const onClick = () => {
    tracker.track('Clicked learn more', {
      category: TRACKER_CATEGORY_HOME_LANDING_PAGE,
      linkUrl,
    });
  };

  return (
    <a
      className="anchor home-learn-more__card-link"
      href={linkUrl}
      onClick={onClick}
      rel="noopener noreferrer"
      target="_blank"
    >
      <div className="home-learn-more__image">
        <ProgressiveImage
          altText=""
          disableWebp
          highResImg={imageUrl.replace('.jpg', '_3x.jpg')}
          highResImg2x={imageUrl.replace('.jpg', '_3x.jpg')}
          lowResImg={imageUrl.replace('/upload', '/upload/e_blur:2000,q_15')}
        />
      </div>
      <h3 className="home-learn-more__subtitle">{subtitle}</h3>
      <div className="home-learn-more__text">{text}</div>
      <span className="home-learn-more__link" data-qa="learnmore-link">
        {t('home_learn-more-link')}
        <span className="arrow-right" />
      </span>
    </a>
  );
};

export default withTranslation('home')(HomeLearnMore);
