import React, { FC } from 'react';
import cn from 'classnames';

import { useCarousel } from '../../hooks/useCarousel';
import { useStore } from '../../contexts/storeContext';

import { TRACKER_CATEGORY_HOME_LANDING_PAGE } from '../../helpers/trackerConstants';

import './carousel.scss';

import arrowLeft from '../../../public/static/images/icons/arrow-left.svg';
import arrowRight from '../../../public/static/images/icons/arrow-right.svg';

type CarouselProps = {
  length: number;
  onActiveSlideChange: (activeSlide: number) => void;
};

export const Carousel: FC<CarouselProps> = ({ children, length, onActiveSlideChange }) => {
  const [active, setActive, style] = useCarousel();
  const { tracker } = useStore();

  const changeActive = (activeSlide: number): void => {
    setActive(activeSlide);
    onActiveSlideChange(activeSlide);

    tracker.track('How urban works slide change', {
      category: TRACKER_CATEGORY_HOME_LANDING_PAGE,
      activeSlide,
    });
  };

  return (
    length > 0 && (
      <div className="carousel">
        <div className="carousel__slider">
          <button
            className={cn('carousel__prev-item', {
              'carousel__prev-item--disabled': active === 0,
            })}
            data-testid="carousel-prev-item"
            data-qa="prevItem-carousel"
            id="js-carousel-prev-item"
            onClick={(): void => changeActive(active - 1)}
            type="button"
          >
            <img alt="" src={arrowLeft} />
          </button>
          <div className="carousel__content" style={style}>
            <div className="carousel__item" id="js-carousel-content">
              {children}
            </div>
          </div>
          <button
            className={cn('carousel__next-item', {
              'carousel__next-item--disabled': active === length - 1,
            })}
            data-testid="carousel-next-item"
            id="js-carousel-next-item"
            data-qa="nextItem-carousel"
            onClick={(): void => changeActive(active + 1)}
            type="button"
          >
            <img alt="" src={arrowRight} />
          </button>
        </div>
        <ol className="carousel__indicators">
          {Array(length)
            .fill(null)
            .map((_, index) => (
              <li
                className={cn('carousel__indicator', {
                  'carousel__indicator--active': active === index,
                })}
                key={`carousel-indicator${index}`} // eslint-disable-line react/no-array-index-key
              >
                <button
                  className="carousel__indicator-button"
                  data-testid={`carousel-indicator${index}`}
                  id={`js-carousel-indicator-${index}`}
                  onClick={(): void => changeActive(index)}
                  type="button"
                />
              </li>
            ))}
        </ol>
      </div>
    )
  );
};

export default Carousel;
