import { inject, observer } from 'mobx-react';
import { Instance } from 'mobx-state-tree';
import React, { FC, useEffect, useState } from 'react';
import { withTranslation, WithTranslationType } from '../server/i18n';
import { LEGACY_APPLICATION } from '../stores/application';
import { Session } from '../stores/session';
import Link from './Link';

interface IContextualLoginButtonProps extends WithTranslationType {
  className: string;
  placementKey: string;
  session: Instance<typeof Session>;
}

const ContextualLoginButon: FC<IContextualLoginButtonProps> = ({
  className,
  placementKey,
  session,
  t,
}) => {
  const [buttonText, setButtonText] = useState(t('new-header_login-button')); // Logged out text
  const [route, setRoute] = useState('/login'); // Logged out route

  useEffect(() => {
    if (session.isLoggedIn) {
      if (session.applicationStatus === LEGACY_APPLICATION.DECLINED) {
        return;
      }

      if (session.applicationStatus !== LEGACY_APPLICATION.SUCCESSFUL) {
        setButtonText(t('new-header_login-application'));
        setRoute('/signup');
      } else {
        setButtonText(t('new-header_login-bookings'));
        setRoute('/bookings');
      }
    }
  }, [session.applicationStatus, session.isLoggedIn]);
  return (
    <Link
      className={className}
      data-qa={`${placementKey}-login-button`}
      id="js-headerLoginButton"
      route={route}
    >
      {buttonText}
    </Link>
  );
};

export default withTranslation('new-header')(
  inject(({ store: { session } }) => ({
    session,
  }))(observer(ContextualLoginButon)),
);
